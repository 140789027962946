<template>
  <div>
    <div v-if="window.width <= 992">
      <mobile-layout />
      <slot />
      <div class="px-5 logged-footer-wrap">
        <Footer className="flex-shrink-0 mobile-auth-footer" />
      </div>
    </div>
    <div 
      v-else 
      class="d-flex flex-column"
    >
      <main class="flex-fill">
        <div class="d-flex flex-row min-vh-100">
          <sidebar />
          <div
            class="flex-grow-1 bg-transparent theme-layout d-flex flex-column align-items-center justify-content-center"
          >
            <slot />
            <div class="w-100">
              <Footer className=" bg-transparent px-5" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer/Footer.vue";
import Sidebar from "../components/Sidebar/Sidebar.vue";
import MobileLayout from "../components/Mobile/MobileLayout.vue";

export default {
    components: { Footer, Sidebar, MobileLayout },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    async created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }
};
</script>
