<template>
  <section :class="style['mobile-layout']">
    <b-navbar
      toggleable="lg"
      type="white"
    >
      <b-container class="mx-0">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between"
        >
          <b-button
            v-b-toggle.mobile-sidebar
            :class="['bg-transparent', style['sidebar-toggle']]"
          >
            <icon-menu
              width="22"
              height="24"
            />
          </b-button>
          <b-sidebar
            id="mobile-sidebar"
            title="Sidebar"
            aria-labelledby="mobile-sidebar-title"
            shadow
            no-header
          >
            <template #default="{ hide }">
              <div
                id="mobile-sidebar-title"
                class="d-flex justify-content-between flex-row px-4 py-2 align-items-center"
              >
                <icon-close
                  width="20"
                  height="20"
                  @click="hide"
                />
                <div class="flex-grow-1 text-center">
                  <img
                    :src="logo"
                    alt="fanpopz-logo"
                    fluid
                    class=""
                    width="90"
                    height="90"
                  >
                </div>
              </div>

              <div class="d-flex justify-content-center flex-column">
                <div class="navbar pl-3 pr-3 bg-blue">
                  <router-link
                    to="/"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                    @click="hide"
                  >
                    <span>
                      <icon-following
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Discover
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <b-link
                    :class="[
                      'align-items-center w-100 justify-content-between d-none',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-events
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Upcoming Events
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </b-link>
                  <router-link
                    v-if="
                      userLogged === null ||
                        (userLogged !== null && account_type == 1)
                    "
                    :to="userLogged === null ? '/register' : '/celebsearch'"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-connects
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Connects
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <b-link
                    v-if="account_type == 2"
                    :class="[
                      'align-items-center w-100 justify-content-between d-none',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-connects
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Connects
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </b-link>
                </div>
                <hr class="hr-primary horizontal-separator">
                <div class="navbar pl-3 pr-0 bg-blue">
                  <router-link
                    v-if="
                      userLogged === null ||
                        (userLogged !== null && account_type == 1)
                    "
                    :to="
                      userLogged === null ? '/register' : '/followers-following'
                    "
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-following
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Following
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <router-link
                    v-if="
                      userLogged === null ||
                        (userLogged !== null && account_type == 2)
                    "
                    :to="
                      userLogged === null ? '/register' : '/followers-following'
                    "
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-following
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      Followers
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <router-link
                    :to="userLogged === null ? '/register' : '/meetings'"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-my-events
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      My Events
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <router-link
                    v-if="userLogged !== null && account_type == 2"
                    :to="
                      userLogged === null
                        ? '/register'
                        : `/products/${encodeFabId}`
                    "
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-collection
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      My Collection
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <router-link
                    v-if="userLogged !== null"
                    to="/cart"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-cart
                        width="24"
                        height="24"
                        class="mr-2"
                      />
                      My Cart
                      <span
                        id="badge"
                        class="badge"
                      >{{
                        cartItems == 0 ? "" : cartItems
                      }}</span>
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                  <!-- <router-link
                    :to="userLogged === null ? '/register' : '/settings'"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                  >
                    <span>
                      <icon-settings
                        width="24"
                        height="24"
                        class="mr-2 settings-icon"
                      />
                      Settings
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </router-link>
                   <b-link
                    v-if="userLogged !== null"
                    :class="[
                      'align-items-center w-100 justify-content-between',
                      style['mobile-nav-link'],
                    ]"
                    @click="showConfirmLogout"
                  >
                    <span>
                      <icon-logout
                        width="24"
                        height="24"
                        class="mr-2 settings-icon"
                      />
                      Logout
                    </span>
                    <icon-caret
                      width="30"
                      height="30"
                      class="pt-2"
                    />
                  </b-link> -->
                </div>
                <ConfirmLogout
                  :show="show"
                  @onClose="handleClose"
                />
              </div>
            </template>
          </b-sidebar>
          <div class="text-center">
            <router-link
              :to="userLogged === null ? '/' : `/feed/${username}`"
              class="navbar-brand font-weight-bold text-center my-6 mr-0"
            >
              <img
                :src="logo"
                alt="fanpopz-logo"
                fluid
                class=""
                width="90"
                height="90"
              >
            </router-link>
          </div>
          <b-nav-item-dropdown
            right
            class="bg-transparent list-unstyled"
            no-caret
          >
            <template #button-content>
              <icon-user
                width="25"
                height="24"
              />
            </template>
            <b-dropdown-item
              v-if="userLogged === null"
              href="/register"
              :class="style['user-nav-item']"
            >
              Register
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userLogged === null"
              href="/login"
              :class="style['user-nav-item']"
            >
              Login
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userLogged !== null"
              href="/settings"
              :class="style['user-nav-item']"
            >
              Settings
            </b-dropdown-item>
            <b-dropdown-item
              v-if="userLogged !== null"
              :class="style['user-nav-item']"
              @click="showConfirmLogout"
            >
              Logout
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
      </b-container>
    </b-navbar>
  </section>
</template>

<script>
import iconMenu from "../../assets/svg-import/menu.svg";
import iconCaret from "../../assets/svg-import/chevron-right.svg";
import iconEvents from "../../assets/svg-import/events.svg";
import iconConnects from "../../assets/svg-import/connects.svg";
import iconMyEvents from "../../assets/svg-import/discount-shape.svg";
//import iconSettings from "../../assets/svg-import/settings.svg";
import iconCollection from "../../assets/svg-import/message-question.svg";
import iconFollowing from "../../assets/svg-import/following.svg";
import iconClose from "../../assets/svg-import/close-sidebar.svg";
import iconUser from "../../assets/svg-import/mobile-user.svg";
import logo from "../../assets/logo/web-logo.png";
import style from "../../styles/mobile-layout.module.scss";
import ConfirmLogout from "../Common/ConfirmLogout";
//import iconLogout from "../../assets/svg-import/logout.svg";
import iconCart from "../../assets/svg-import/buy.svg";
export default {
    components: {
        iconMenu,
        iconCaret,
        iconFollowing,
        iconEvents,
        iconConnects,
        iconMyEvents,
        iconCollection,
        iconClose,
        iconUser,
        iconCart,
        ConfirmLogout,
    },
    data() {
        return {
            style,
            logo,
            userLogged: localStorage.getItem("user-token"),
            username: localStorage.getItem("username"),
            account_type: localStorage.getItem("account-type"),
            userId: localStorage.getItem("userId"),
            fabId: localStorage.getItem("fabId"),
            encodeFabId: "",
            show: false,
            cartItems: localStorage.getItem("totalProducts"),
        };
    },
    watch: {
        fabId(val) {
            this.encodeFabId = window.btoa(val);
        },
    },
    mounted() {
        this.encodeFabId = window.btoa(this.fabId);
    },
    methods: {
        showConfirmLogout() {
            this.show = true;
        },
        handleClose() {
            this.show = false;
        },
        handleFeedRedirection() {
            if (this.account_type === "1") {
                localStorage.setItem("fabId", "");
            }
            window.location.href = `/feed/${this.username}`;
        },
    },
};
</script>
