<template>
  <div
    :class="style['sidebar-wrapper']"
    :style="{ height: window.height + 'px' }"
  >
    <div class="sidebar">
      <div :class="[style['sidebar-content'], 'w-100 text-center']">
        <router-link
          v-if="userLogged === null"
          id="logo"
          to="/"
          class="navbar-brand font-weight-bold text-center my-6"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="sidebar-logo"
          >
        </router-link>
        <b-link
          v-else
          id="logo"
          class="navbar-brand font-weight-bold text-center my-6"
          @click="handleFeedRedirection()"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            class="sidebar-logo"
          >
        </b-link>
        <div
          :class="style['sidebar-menu']"
          :style="{ height: menu_list_height + 'px' }"
        >
          <div class="navbar py-2 bg-blue">
            <router-link
              v-if="userLogged === null"
              to="/"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-following
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Discover
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <b-link
              v-else
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
              @click="handleFeedRedirection()"
            >
              <span>
                <icon-following
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Discover
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </b-link>
            <router-link
              :to="userLogged === null ? '/register' : '/upcoming-events'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-events
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Upcoming Events
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="
                userLogged === null ||
                  (userLogged !== null &&
                    (account_type == 1 || account_type == 2))
              "
              :to="userLogged === null ? '/register' : '/celebsearch'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-connects
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Connects
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="
                userLogged === null ||
                  (userLogged !== null && account_type == 1)
              "
              :to="userLogged === null ? '/register' : '/followers-following'"
              :class="[
                'align-items-center w-100 justify-content-between d-none',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-connects
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Following
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <b-link
              v-if="account_type == 2"
              :class="[
                'align-items-center w-100 justify-content-between d-none',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-connects
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Connects
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </b-link>
          </div>
          <hr class="hr-primary horizontal-separator my-1">
          <div class="navbar py-2 bg-blue">
            <router-link
              v-if="
                userLogged === null ||
                  (userLogged !== null && account_type == 1)
              "
              :to="userLogged === null ? '/register' : '/followers-following'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-following
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Following
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="
                userLogged === null ||
                  (userLogged !== null && account_type == 2)
              "
              :to="userLogged === null ? '/register' : '/followers-following'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-following
                  width="24"
                  height="24"
                  class="mr-2"
                />
                Followers
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              :to="userLogged === null ? '/register' : '/meetings'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-my-events
                  width="24"
                  height="24"
                  class="mr-2"
                />
                My Events
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="userLogged !== null"
              to="/cart"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-cart
                  width="24"
                  height="24"
                  class="mr-2"
                />
                My Cart
                <span
                  id="badge"
                  class="badge"
                >{{
                  cartItems == 0 ? "" : cartItems
                }}</span>
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="userLogged !== null && account_type == 2"
              :to="
                userLogged === null ? '/register' : `/products/${encodeFabId}`
              "
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-collection
                  width="24"
                  height="24"
                  class="mr-2"
                />
                My Collection
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <router-link
              v-if="userLogged !== null"
              :to="userLogged === null ? '/register' : '/settings'"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
            >
              <span>
                <icon-settings
                  width="24"
                  height="24"
                  class="mr-2 settings-icon"
                />
                Settings
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
              <icon-bottom-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-top-shape']]"
              />
              <icon-top-shape
                width="30"
                height="30"
                :class="['pt-2', style['link-bottom-shape']]"
              />
            </router-link>
            <b-link
              v-if="userLogged !== null"
              :class="[
                'align-items-center w-100 justify-content-between',
                style['home-nav-link'],
              ]"
              @click="showConfirmLogout"
            >
              <span>
                <icon-logout
                  width="24"
                  height="24"
                  class="mr-2 settings-icon"
                />
                Logout
              </span>
              <icon-caret
                width="30"
                height="30"
                class="pt-2"
              />
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <ConfirmLogout
      :show="show"
      @onClose="handleClose"
    />
  </div>
</template>
<script>
import logo from "../../assets/logo/web-logo.png";
import iconCaret from "../../assets/svg-import/chevron-right.svg";
//import iconDiscover from "../../assets/svg-import/wallet-money 2.svg";
import iconEvents from "../../assets/svg-import/events.svg";
import iconConnects from "../../assets/svg-import/connects.svg";
import iconMyEvents from "../../assets/svg-import/discount-shape.svg";
import iconSettings from "../../assets/svg-import/settings.svg";
import iconCollection from "../../assets/svg-import/message-question.svg";
import iconFollowing from "../../assets/svg-import/following.svg";
import iconTopShape from "../../assets/svg-import/top-shape.svg";
import iconBottomShape from "../../assets/svg-import/bottom-shape.svg";
import iconLogout from "../../assets/svg-import/logout.svg";
import iconCart from "../../assets/svg-import/buy.svg";
import style from "../../styles/sidebar.module.scss";
import ConfirmLogout from "../Common/ConfirmLogout";
export default {
    components: {
        iconCaret,
        // iconDiscover,
        iconEvents,
        iconConnects,
        iconMyEvents,
        iconSettings,
        iconCollection,
        iconFollowing,
        ConfirmLogout,
        iconTopShape,
        iconBottomShape,
        iconLogout,
        iconCart,
    },
    data() {
        return {
            logo,
            style,
            window: {
                width: 0,
                height: 0,
            },
            userLogged: localStorage.getItem("user-token"),
            username: localStorage.getItem("username"),
            account_type: localStorage.getItem("account-type"),
            userId: localStorage.getItem("userId"),
            fabId: localStorage.getItem("fabId"),
            encodeFabId: "",
            show: false,
            cartItems: localStorage.getItem("totalProducts"),
            logo_height: 0,
            menu_list_height: 0,
        };
    },
    watch: {
        fabId(val) {
            this.encodeFabId = window.btoa(val);
        },
    },
    mounted() {
        this.encodeFabId = window.btoa(this.fabId);
        this.handleResize();
    },
    async created() {
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            let logo = document.getElementById("logo");
            if (logo) {
                this.logo_height = logo.offsetHeight;
                this.menu_list_height = this.window.height - this.logo_height;
            }
        },
        showConfirmLogout() {
            this.show = true;
        },
        handleClose() {
            this.show = false;
        },
        handleFeedRedirection() {
            if (this.account_type === "1") {
                localStorage.setItem("fabId", "");
            }
            window.location.href = `/feed/${this.username}`;
        },
    },
};
</script>
