<template>
  <b-modal
    id="modal-logut"
    v-model="show"
    centered
    hide-footer
    size="md"
    body-class="text-center pb-5"
    header-class="border-bottom-0"
  >
    <template
      v-slot:modal-header=""
    >
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="handleClose"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <p class="display-4 font-weight-bold">
      Are you sure ?
    </p>

    <b-button
      class="btn btn-primary rounded-pill px-5 mb-4 mr-3"
      @click="handleClose"
    >
      Cancel
    </b-button>
    
    <router-link
      to="/logout"
      class="btn btn-primary rounded-pill px-5 mb-4"
    >
      Logout
    </router-link>
  </b-modal>
</template>

<script>
import IconClose from '../../assets/svg-import/close.svg';
export default {
    name: 'Logout',
    components: {IconClose},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {};
    },
    methods: {
        handleClose() {
            this.$emit('onClose');
        }
    }
};
</script>
