<template>
  <div class="bg-transparent footer-container">
    <div
      v-if="window.width <= 992"
      class="py-5"
    >
      <b-row class="mx-0">
        <b-col cols="12">
          <h6 class="text-white pt-4 pl-0 text-center">
            Tell us which celebrity should join FanPopz
          </h6>
          <div class="form-group mdi mb-3">
            <input
              id="suggestedFab"
              v-model="suggestedFab"
              placeholder="Suggest your popz"
              name="email"
              type="text"
              :class="[
                'form-control border-left-0 border-right-0 border-top-0 bg-transparent text-white pl-0',
                style['suggest-fab-input'],
              ]"
              @keyup.enter="handleSuggestFab"
            >
          </div>
          <span
            id="input-1-live-name"
            class="valdiation_error_home"
          >{{
            veeErrors.first("email")
          }}</span>
        </b-col>
      </b-row>
      <mobile-footer-links />
      <b-row>
        <b-col cols="12">
          <div
            class="flex-shrink-0 d-flex align-items-center justify-content-center pt-5"
          >
            <a class="mr-4 text-white">
              <icon-facebook
                width="24"
                height="24"
              />
            </a>
            <a class="mr-4 text-white">
              <icon-twitter
                width="24"
                height="24"
              />
            </a>
            <a class="text-white">
              <icon-instagram
                width="24"
                height="24"
              />
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      class="py-5"
    >
      <hr class="footer-separator border-white mb-0 pb-5">
      <b-row class="mx-0">
        <b-col cols="6">
          <div class="d-md-flex align-items-center flex-wrap">
            <b-nav
              fill
              class="d-md-flex flex-grow-1 mr-md-4 flex-column"
            >
              <b-nav-item
                link-classes="text-white text-left"
                to="/about"
              >
                About
              </b-nav-item>
              <b-nav-item
                link-classes="text-white text-left"
                to="/contact-us"
              >
                Contact
              </b-nav-item>
              <b-nav-item
                link-classes="text-white text-left"
                to="/termsandconditions"
              >
                Terms & Conditions
              </b-nav-item>
              <b-nav-item
                link-classes="text-white text-left"
                to="/policy"
              >
                Privacy Policy
              </b-nav-item>
              <b-nav-item link-classes="text-white text-left">
                Cookies
              </b-nav-item>
            </b-nav>
          </div>
        </b-col>
        <b-col cols="6">
          <h5 class="text-white pt-4 pl-0 text-left">
            Tell us which celebrity should join FanPopz
          </h5>
          <div class="form-group mdi mb-3">
            <input
              id="suggestedFab"
              v-model="suggestedFab"
              placeholder="Suggest your popz"
              name="email"
              type="text"
              :class="[
                'form-control border-left-0 border-right-0 border-top-0 bg-transparent text-white pl-0',
                style['suggest-fab-input'],
              ]"
              @keyup.enter="handleSuggestFab"
            >
          </div>
          <span
            id="input-1-live-name"
            class="valdiation_error_home"
          >{{
            veeErrors.first("email")
          }}</span>
          <div
            class="flex-shrink-0 d-flex align-items-center justify-content-start pt-5"
          >
            <a class="mr-4 text-white">
              <icon-facebook
                width="24"
                height="24"
              />
            </a>
            <a class="mr-4 text-white">
              <icon-twitter
                width="24"
                height="24"
              />
            </a>
            <a class="text-white">
              <icon-instagram
                width="24"
                height="24"
              />
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { addRequest } from "../../api/home";
import { _sendResponse } from "../../js/common-function";
import style from "../../styles/footer-links.module.scss";
import IconFacebook from "../../assets/svg-import/facebook.svg";
import IconInstagram from "../../assets/svg-import/instagram.svg";
import IconTwitter from "../../assets/svg-import/twitter.svg";
import MobileFooterLinks from "./MobileFooterLinks.vue";
export default {
    components: {
        IconFacebook,
        IconInstagram,
        IconTwitter,
        MobileFooterLinks,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            suggestedFab: "",
            style,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleRedirection(link) {
            window.location.href = link;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        async handleSuggestFab() {
            if (this.suggestedFab) {
                await addRequest("2", this.suggestedFab);
                this.suggestedFab = "";
            } else {
                _sendResponse("error", "Please enter a popz name.");
            }
        },
    },
};
</script>
