var nodemode = process.env.NODE_ENV;
var API_BASE_URL;
var ASSETS_URL;
var BUCKET_NAME;
var S3_ACCESSKEYID;
var S3_SECRETACCESSKEY;
var API_IMAGE_URL;
var S3_BUCKET_URL;
var S3_REGION;
var REDIRECTION_URL;
var IMAGE_URL;
var ZOOM_KEY;
var ZOOM_SECRET_KEY;
var GOOGLE_CLIENT_ID;
var GOOGLE_CLIENT_SECRET;
var PAYPAL_CLIENT_ID;

if (nodemode === "development") {
    /*API_BASE_URL = "http://3.137.127.137/gabfab/api/v2";
    ASSETS_URL = "http://3.137.127.137/gabfab/public";
    BUCKET_NAME = "gabfab";
    S3_ACCESSKEYID = "AKIASP65H4ITOSWUY6GR";
    S3_SECRETACCESSKEY = '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';
    API_IMAGE_URL = 'http://3.137.127.137/gabfab/public/image/noimage.jpg';
    S3_BUCKET_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    S3_REGION = "us-east-2";
    REDIRECTION_URL='http://3.137.127.137/gabfab/public/';
    IMAGE_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    ZOOM_KEY = 'lh4G7I5WQSKl-PVoxEv-WA';
    ZOOM_SECRET_KEY = 'lrVKviGbdDQ0VvuLxXyNbyeaK2eqQ6kULlMW';
    GOOGLE_CLIENT_ID = '124790567523-r7hajetlhocu6qiff2jkuefdut7mhvbv.apps.googleusercontent.com';
    GOOGLE_CLIENT_SECRET = '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';
    PAYPAL_CLIENT_ID = 'Ab0VpGBjC_e24GsmjGTrwPpBqLQDNXzdvueUbWlYGpE3ph5ZzAnWwFdwQoH1ddBzX_Lz37CbgxGslsxA';*/


    API_BASE_URL = "http://localhost/gabfab/api/v2";
    ASSETS_URL = "http://localhost/gabfab/public";
    BUCKET_NAME = "gabfab";
    S3_ACCESSKEYID = "AKIASP65H4ITOSWUY6GR";
    S3_SECRETACCESSKEY = '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';
    API_IMAGE_URL = 'http://localhost/gabfab/public/image/noimage.jpg';
    S3_BUCKET_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    S3_REGION = "us-east-2";
    REDIRECTION_URL='http://localhost/gabfab/public/';
    IMAGE_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    ZOOM_KEY = 'lh4G7I5WQSKl-PVoxEv-WA';
    ZOOM_SECRET_KEY = 'lrVKviGbdDQ0VvuLxXyNbyeaK2eqQ6kULlMW';
    GOOGLE_CLIENT_ID = '124790567523-lc0qdu6rr1thn9923ro0l0n3ms46bo9f.apps.googleusercontent.com';
    GOOGLE_CLIENT_SECRET = 'LB-om84iWzzqYb7GRmmg7YO0';
    PAYPAL_CLIENT_ID = 'Ab0VpGBjC_e24GsmjGTrwPpBqLQDNXzdvueUbWlYGpE3ph5ZzAnWwFdwQoH1ddBzX_Lz37CbgxGslsxA';
} else {

    //For Dev Environment
    /*API_BASE_URL = 'http://3.137.127.137/gabfab/api/v2';
    ASSETS_URL = 'http://3.137.127.137/gabfab/public';
    BUCKET_NAME = "gabfab";
    S3_ACCESSKEYID = "AKIASP65H4ITOSWUY6GR";
    S3_SECRETACCESSKEY = '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';
    API_IMAGE_URL = 'http://3.137.127.137/gabfab/public/image/noimage.jpg';
    S3_BUCKET_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    S3_REGION = "us-east-2";
    REDIRECTION_URL='http://3.137.127.137/gabfab/public/';
    IMAGE_URL = 'https://gabfab.s3.us-east-2.amazonaws.com';
    PAYPAL_CLIENT_ID = 'Ab0VpGBjC_e24GsmjGTrwPpBqLQDNXzdvueUbWlYGpE3ph5ZzAnWwFdwQoH1ddBzX_Lz37CbgxGslsxA'; */

    //For Old Staging Environment
    /*API_IMAGE_URL = 'https://devgabfabapi.gymclock.app/public/image/noimage.jpg';
    API_BASE_URL = 'https://devgabfabapi.gymclock.app/api/v2';
    ASSETS_URL = 'https://devgabfabapi.gymclock.app/public';
    BUCKET_NAME = "devgabfab.gymclock.app";
    S3_ACCESSKEYID = "AKIAVDBMSBZMJDGU76GN";
    S3_SECRETACCESSKEY = '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';
    S3_BUCKET_URL = 'https://devgabfab.gymclock.app';
    S3_REGION = "us-east-1";
    REDIRECTION_URL='https://devgabfab.gymclock.app/';
    IMAGE_URL = 'https://devgabfab.gymclock.app';*/

    //For Staging Environment
    /* API_IMAGE_URL = 'https://stagingadmin.fanpopz.com/public/image/noimage.jpg';
    API_BASE_URL = 'https://stagingadmin.fanpopz.com/api/v2';
    ASSETS_URL = 'https://stagingadmin.fanpopz.com/public';
    BUCKET_NAME = "staging.fanpopz.com";
    S3_ACCESSKEYID = "AKIAXDQ7NYFFT75E6NNI";
    S3_SECRETACCESSKEY = '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';
    S3_BUCKET_URL = 'https://staging.fanpopz.com';
    S3_REGION = "us-east-1";
    REDIRECTION_URL='https://staging.fanpopz.com/';
    IMAGE_URL = 'https://staging.fanpopz.com';
    PAYPAL_CLIENT_ID = 'Ab0VpGBjC_e24GsmjGTrwPpBqLQDNXzdvueUbWlYGpE3ph5ZzAnWwFdwQoH1ddBzX_Lz37CbgxGslsxA'; */

    //For Production Environment
    API_IMAGE_URL = 'https://admin.fanpopz.com/public/image/noimage.jpg';
    API_BASE_URL = 'https://admin.fanpopz.com/api/v2';
    ASSETS_URL = 'https://admin.fanpopz.com/public';
    BUCKET_NAME = "fanpopz.com";
    S3_ACCESSKEYID = "AKIAXDQ7NYFFT75E6NNI";
    S3_SECRETACCESSKEY = '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';
    S3_BUCKET_URL = 'https://fanpopz.com';
    S3_REGION = "us-east-1";
    REDIRECTION_URL='https://fanpopz.com/';
    IMAGE_URL = 'https://fanpopz.com'; 
    PAYPAL_CLIENT_ID = 'AeADPVsQzY5R52XGBzx_mrRBWcGza_wjHDm-5k13AdPa3IOPQa7h6NFInn5InloAAu8lJX6CXnoWdJxe'; 

    ZOOM_KEY = 'lh4G7I5WQSKl-PVoxEv-WA';
    ZOOM_SECRET_KEY = 'lrVKviGbdDQ0VvuLxXyNbyeaK2eqQ6kULlMW';
    GOOGLE_CLIENT_ID = '124790567523-r7hajetlhocu6qiff2jkuefdut7mhvbv.apps.googleusercontent.com';
    GOOGLE_CLIENT_SECRET = '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';
    
}

export {
    API_BASE_URL,
    ASSETS_URL,
    BUCKET_NAME,
    S3_ACCESSKEYID,
    S3_SECRETACCESSKEY,
    API_IMAGE_URL,
    S3_BUCKET_URL,
    S3_REGION,
    REDIRECTION_URL,
    IMAGE_URL,
    ZOOM_KEY,
    ZOOM_SECRET_KEY,
    GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET,
    PAYPAL_CLIENT_ID
};
