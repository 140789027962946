import Vue from 'vue';
import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import moment from 'moment-timezone';
import { 
    BUCKET_NAME,
    S3_ACCESSKEYID,
    S3_SECRETACCESSKEY,
    API_BASE_URL,
    S3_REGION,
    API_IMAGE_URL
} from "../config/config";
import AWS from 'aws-sdk';
import axios from 'axios';

AWS.config.update({
    accessKeyId: S3_ACCESSKEYID,
    secretAccessKey: S3_SECRETACCESSKEY,
    BUCKET_NAME: BUCKET_NAME,
    region:S3_REGION
});

var s3 = new AWS.S3();

Vue.use(VueToast);

function _sendResponse (type, message) {
    Vue.$toast.open({
        position: 'top-right',
        message: message,
        type: type,
        duration: 5000,
        dismissible: true
    });
}

/**
 * 
 * @param {String} date 
 * @returns time, minutes from current time. eg. 1 day, 2 hours
 */
function getFromNowTime(date) {
    return moment(date).fromNow(true);
}

// upload file to bucket
function uploadToBucket(file,path){
    return new Promise((resolve, reject) => {

        var filename = file;
        var filetype = filename.type;
        var fileextension = filename.name.split('.').pop();
        var imagename = new Date().getTime();

        var params = {
            'Body': file,
            'Bucket': BUCKET_NAME,
            'Key': path+imagename+'.'+fileextension,
            'ACL': 'public-read', /* This makes the image public, but only works if your S3 bucket allows public access */
            'ContentType': filetype, /* This is important to handle jpg vs png etc */
        };

        s3.upload(params, async function (err, data) {
            if (err) {
                removeFromBucket(params.Key);
                reject(err);
            } else {
                resolve( "/"+data.Key);
            }
        });

    });
}

// remove file from Bucket
function removeFromBucket(path){
    return new Promise((resolve, reject) => {
        s3.deleteObject({
            Bucket: BUCKET_NAME,
            Key: path,
        },function (err,data){
            if (err){
                reject('Error : upload image delete', err);
            }
            resolve('Upload image delete', data);
        });
    });
}

/**
 * 
 * @param {String} date 
 * @returns convert date to particular format like 31/06 DD/MM
 */
function dateFormat(date) {
    var today = moment().format('DD/MM/YY');
    var video_call_date = moment(date).format('DD/MM/YY');
    if (today === video_call_date){
        return "Today";
    } else {
        return moment(date).format('DD/MM/YY');
    }
}

function timeFormat(date) {
    return moment(date).format('h:mm A');
}

/**
 * 
 * @param {String} url API url 
 * @param {*} data Data to be send
 * @param {*} method GET, POST, DELETE
 * @param {*} headers 
 * @returns 
 */
async function apiCall(url, data, method='GET', headers=false){
    var date = new Date();

    let timezone = moment(date).format('Z');
    const options = {
        method: method,
        url: API_BASE_URL + url,
    };
    if (headers){
        options.headers = {
            Authorization:'Bearer ' + localStorage.getItem('user-token'), 
            'HTTP_HOST': 'WEB',
            'HTTP_TIME_ZONE':timezone
        };
    } else {
        options.headers = {
            'HTTP_HOST': 'WEB',
            'HTTP_TIME_ZONE':timezone
        };
    }
    if (data !== null){
        options.data = data;
    }
    let response = await axios.request(options);
    return response;
}

function setPlaceholderProfileImg(event) {
    event.target.src=API_IMAGE_URL;
}

export {
    _sendResponse,
    getFromNowTime,
    uploadToBucket,
    removeFromBucket,
    dateFormat,
    timeFormat,
    apiCall,
    s3,
    setPlaceholderProfileImg
};
