<template>
  <b-row class="mx-0">
    <b-col cols="12">
      <div class="d-md-flex align-items-center flex-wrap">
        <b-nav
          fill
          class="d-md-flex flex-grow-1 mr-md-4 flex-column"
        >
          <b-nav-item
            link-classes="text-white text-center"
            to="/about"
          >
            About
          </b-nav-item>
          <b-nav-item
            link-classes="text-white text-center"
            to="/contact-us"
          >
            Contact
          </b-nav-item>
          <b-nav-item
            link-classes="text-white text-center"
            to="/termsandconditions"
          >
            Terms & Conditions
          </b-nav-item>
          <b-nav-item
            link-classes="text-white text-center"
            to="/policy"
          >
            Privacy Policy
          </b-nav-item>
          <b-nav-item link-classes="text-white text-center">
            Cookies
          </b-nav-item>
        </b-nav>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {};
</script>
