import { APIUrlList } from '../js/api_url';
import { _sendResponse } from '../js/common-function';
import { apiCall } from '../js/common-function';


export const fabList = async (category_id = null) => {
    try {
        let url = APIUrlList.SUGGESTFABLIST;
        if (category_id !== null){
            url = APIUrlList.SUGGESTFABLIST + `?category_id=${category_id}`;
        }
        let response = await apiCall(url, null, 'GET', false);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const addRequest = async (type, content) => {
    try {
        let ObjToSend = {
            request_type: type,
            request_content: content
        }; 
        
        let response = await apiCall(APIUrlList.ADDREQUEST, ObjToSend, 'POST', false);
        if (response.data.status){
            _sendResponse('success', response.data.message);
        } else {
            _sendResponse('error', response.data.message);
        }
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};

export const settingsList = async () => {
    try {
        let response = await apiCall(APIUrlList.SETTINGSLIST, {}, 'GET', false);
        return response.data.data;
    } catch (error){
        _sendResponse('error', error);
    }
};