var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.style['sidebar-wrapper'],style:({ height: _vm.window.height + 'px' })},[_c('div',{staticClass:"sidebar"},[_c('div',{class:[_vm.style['sidebar-content'], 'w-100 text-center']},[(_vm.userLogged === null)?_c('router-link',{staticClass:"navbar-brand font-weight-bold text-center my-6",attrs:{"id":"logo","to":"/"}},[_c('img',{staticClass:"sidebar-logo",attrs:{"src":_vm.logo,"alt":"fanpopz-logo","fluid":""}})]):_c('b-link',{staticClass:"navbar-brand font-weight-bold text-center my-6",attrs:{"id":"logo"},on:{"click":function($event){return _vm.handleFeedRedirection()}}},[_c('img',{staticClass:"sidebar-logo",attrs:{"src":_vm.logo,"alt":"fanpopz-logo","fluid":""}})]),_c('div',{class:_vm.style['sidebar-menu'],style:({ height: _vm.menu_list_height + 'px' })},[_c('div',{staticClass:"navbar py-2 bg-blue"},[(_vm.userLogged === null)?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":"/"}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Discover ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_c('b-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],on:{"click":function($event){return _vm.handleFeedRedirection()}}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Discover ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1),_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/upcoming-events'}},[_c('span',[_c('icon-events',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Upcoming Events ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1),(
              _vm.userLogged === null ||
                (_vm.userLogged !== null &&
                  (_vm.account_type == 1 || _vm.account_type == 2))
            )?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/celebsearch'}},[_c('span',[_c('icon-connects',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Connects ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(
              _vm.userLogged === null ||
                (_vm.userLogged !== null && _vm.account_type == 1)
            )?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between d-none',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/followers-following'}},[_c('span',[_c('icon-connects',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Following ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.account_type == 2)?_c('b-link',{class:[
              'align-items-center w-100 justify-content-between d-none',
              _vm.style['home-nav-link'] ]},[_c('span',[_c('icon-connects',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Connects ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e()],1),_c('hr',{staticClass:"hr-primary horizontal-separator my-1"}),_c('div',{staticClass:"navbar py-2 bg-blue"},[(
              _vm.userLogged === null ||
                (_vm.userLogged !== null && _vm.account_type == 1)
            )?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/followers-following'}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Following ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(
              _vm.userLogged === null ||
                (_vm.userLogged !== null && _vm.account_type == 2)
            )?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/followers-following'}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Followers ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/meetings'}},[_c('span',[_c('icon-my-events',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Events ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1),(_vm.userLogged !== null)?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":"/cart"}},[_c('span',[_c('icon-cart',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Cart "),_c('span',{staticClass:"badge",attrs:{"id":"badge"}},[_vm._v(_vm._s(_vm.cartItems == 0 ? "" : _vm.cartItems))])],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.userLogged !== null && _vm.account_type == 2)?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : ("/products/" + _vm.encodeFabId)}},[_c('span',[_c('icon-collection',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Collection ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.userLogged !== null)?_c('router-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/settings'}},[_c('span',[_c('icon-settings',{staticClass:"mr-2 settings-icon",attrs:{"width":"24","height":"24"}}),_vm._v(" Settings ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}}),_c('icon-bottom-shape',{class:['pt-2', _vm.style['link-top-shape']],attrs:{"width":"30","height":"30"}}),_c('icon-top-shape',{class:['pt-2', _vm.style['link-bottom-shape']],attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.userLogged !== null)?_c('b-link',{class:[
              'align-items-center w-100 justify-content-between',
              _vm.style['home-nav-link'] ],on:{"click":_vm.showConfirmLogout}},[_c('span',[_c('icon-logout',{staticClass:"mr-2 settings-icon",attrs:{"width":"24","height":"24"}}),_vm._v(" Logout ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e()],1)])],1)]),_c('ConfirmLogout',{attrs:{"show":_vm.show},on:{"onClose":_vm.handleClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }