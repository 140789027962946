<template>
  <section :class="style['mobile-layout']">
    <b-row :class="['mx-0', style['auth-container']]">
      <b-col
        cols="12"
        :class="style['auth-image']"
      >
        <router-link
          to="/"
          class="navbar-brand font-weight-bold my-6 pr-4"
        >
          <img
            :src="logo"
            alt="fanpopz-logo"
            fluid
            :class="style['auth-logo']"
          >
        </router-link>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import image from "../../assets/slide-1.png";
import style from "../../styles/mobile-layout.module.scss";
import logo from "../../assets/logo/logo-white-text.png";
export default {
    data() {
        return {
            image,
            style,
            logo,
        };
    },
};
</script>
