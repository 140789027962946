import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
 
const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem('user-token') != null) {
        next();
        return;
    }
    next('/');
}; 

const ifProfileUpdated = (to, from, next) => {
    if (localStorage.getItem('connectedVia') == 2 || localStorage.getItem('connectedVia') == 1 && localStorage.getItem('account-type') == 2){
        if (localStorage.getItem('isProfileUpdated') == 1 ){
            next();
            return;
        } 
        next('/settings');
        return;
    }
    next();
};

const ifNotAuthenticated = (to, from, next) => {
    if (localStorage.getItem('user-token') == null) {
        next();
        return;
    }
    next('/');
};

const ifLoggedIn = (to, from, next) => {
    if (localStorage.getItem('user-token') !== null){
        if (localStorage.getItem('isProfileUpdated') == 0){
            next('/settings');
            return;
            
        }
        next('/feed/'+localStorage.getItem('username'));
        return;
        
        
    }
    next();
};

const routes = [
    { path: "/", component: ()=> import('./views/Home/Home'), meta: { layout: "Home" }, beforeEnter:ifLoggedIn },
    { path: "/register", name: "register", component: ()=>import('./views/Register'), beforeEnter: ifNotAuthenticated },
    { path: "/login", name: "login", component: ()=>import('./views/Login'), beforeEnter: ifNotAuthenticated },
    { path: "/feed", component: ()=>import('./views/Feed/Feed'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    { path: "/feed/:username", component: ()=> import ('./views/Feed/Feed'), beforeEnter: ifProfileUpdated },
    { path: "/forgotpassword", component: () => import('./views/ForgotPassword'), beforeEnter: ifNotAuthenticated },
    { path: "/code/verify/:id", component: () => import('./views/VerifyCode'), beforeEnter: ifNotAuthenticated },
    { path: "/password/reset/:id", component: () => import ('./views/ResetPassword'), beforeEnter: ifNotAuthenticated},
    { path: "/ads/:id", component: ()=>import('./components/Advertisements/AdvertiseDetail'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    {
        path: '/verify_user/:id',
        component: ()=> import ('./views/Home/Home'), meta: { layout: "Home", beforeEnter: ifNotAuthenticated },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import ('./components/Common/Logout'),
    },
    {path:'/celebsearch', component:()=>import ('./views/CelebSearch'), meta:{layout:"Gab"},beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: '/settings', component: ()=>import('./views/FabSettings'), meta:{layout:"Gab"}, beforeEnter:ifAuthenticated },
    {path: "/store", component: ()=>import('./views/Store'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    {path: "/meetings", component: ()=>import('./views/Meetings/Meetings'), beforeEnter: ifAuthenticated  && ifProfileUpdated},
    {path: "/upcoming-events", component: ()=>import('./views/UpcomingEvents/UpcomingEvents'), beforeEnter: ifAuthenticated && ifProfileUpdated },
    {path: "/products/:id", component:()=>import('./components/Common/ProductStore'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    {path: "/cart", component:()=>import('./views/ShoppingCart'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: "/payment", component:()=>import('./components/Shopping/Payment'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    {path: "/product/:id", component:()=>import('./components/Shopping/ProductDetails'), beforeEnter: ifAuthenticated && ifProfileUpdated},
    {path: '/booking/:type', component:()=>import('./components/Bookings/BookMeeting'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: '/cart/checkout', component:()=>import('./components/Shopping/Checkout'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: '/guide/:type', component:()=>import('./components/Bookings/VideoGuide'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: '/termsandconditions' , component:()=>import('./views/TermsAndConditions')},
    {path: '/ourtermsandconditions' , component:()=>import('./views/StaticPages/TermsAndConditionsContent'), meta:{layout:"StaticPage"}},
    {path: '/about', component:()=>import('./views/AboutUs')},
    {path: '/aboutus', component:()=>import('./views/StaticPages/AboutUsContent'), meta:{layout:"StaticPage"}},
    {path: '/policy', component:()=>import('./views/PrivacyPolicy')},
    {path: '/privacypolicy', component:()=>import('./views/StaticPages/PrivacyContent'), meta:{layout:"StaticPage"}},
    {path: "/contact-us", component:()=>import('./views/ContactUs') },
    {path: '/call/:id', component:()=>import('./components/Meetings/StartCall'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: '/profile/:username/:gabId', component:()=>import('./components/Meetings/GabProfile'), beforeEnter:ifAuthenticated && ifProfileUpdated},
    {path: "/payment-error", component:()=>import('./views/PaymentError') },
    {path: "/payment-success", component:()=>import('./components/Bookings/Step3') },
    {path: "/followers-following/:username?", component:()=>import('./views/FollowersFollowing'), meta:{layout:"Gab"},beforeEnter:ifAuthenticated && ifProfileUpdated},
];

export const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes
});
