<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const defaultLayout = "Default";

export default {
    name: 'App',
    computed: {
        layout() {
            return "Layout" + (this.$route.meta.layout || defaultLayout);
        },
    },
};
</script>
