import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        message: 'Hello, From Vuex'
    },
    mutations: {},
    getters: {},
});

export default store;