export const APIUrlList = Object.freeze({
    USERLIST: '/user_list',
    REGISTRATION: '/registration',
    SOCIALLOGIN: '/social_login',
    LOGIN: '/login',
    FORGOTPASSWORD: '/forgot_password',
    RESETPASSWORD: '/reset_password',
    USERCONFIRM: '/user/confirm',
    RESETLINKVERIFY: '/reset_link_verify',
    CATEGORYLIST:'/getCategoryList',
    SEARCHFAB:'/searchFabList',
    FOLLOWFAB: '/followFab',
    SUGGESTFABLIST:'/suggestFabList',
    PROFILEDETAILS:'/profile',
    UPDATEPROFILE:'/profile/update',
    POSTLIST:'/getPostList',
    LIKEPOSTCOMMENT:'/likePostComment',
    ADDPOST:'/addPost',
    PRODUCTLIST:'/getProductList',
    GETCATEGORYLIST: '/getCategoryList',
    GETUSERPROFILE: '/getUserProfile',
    GETFABUPCOMINGEVENT: '/getFabUpCommingEventList',
    CHANGEFABSETTING: '/changeFabSetting',
    CHANGEPROFILEPIC: '/changeProfilePic',
    ADDPOSTCOMMENTS: '/addPostComment',
    DELETEPOST: '/deletePost',
    GETADSLIST: '/getAdsList',
    ADDREQUEST: '/addRequest',
    ADDMEETINGSLOT: '/addFabSlot',
    UPDATEMEETINGSLOT: '/updateFabSlot',
    GETTIMEZONELIST: '/getTimeZoneList',
    GETFABCALENARLIST: '/getFabCalenderList',
    GETGABCALENARLIST: '/getGabCalenderList',
    SHOWMORETIMESLOT: '/showMoreTimeSlot',
    GETADSDETAILS:'/getAdsDetail',
    ADDEDITPRODUCT:'/addEditProduct',
    DELETEPRODUCT: '/deleteProduct',
    SENDADSINFO:'/sendAdsInfo',
    CARTPRODUCTLIST:'/cartList',
    ADDTOCART:'/addToCart',
    REMOVEFROMCART:'/removeFromCart',
    GETPRODUCTDETAILS:'/getProductDetail',
    GETSETTINGSLIST:'/getSettingsList',
    SENDCONTACTUS:'/contactUs',
    GETPAGECONTENT:'/getPageContent',
    CREATEBOOKING:'/bookings',
    ADDEXCLUSIVEPOST:'/addExclusivePost',
    SETTINGSLIST: '/getSettingsList',
    ADDORDER:'/addOrder',
    ADDORDERITEMS:'/addOrderItems',
    ZOOMSIGNATURE:'/zoom/getSignature',
    MEETINGDETAILS:'/getMeetingDetails',
    UPDATEFABSETTING:'/updateFabSetting',
    SLOTDETAILS:'/get_slot_detail',
    CANCELSLOT: '/cancelFabSlot',
    SLOTGABLIST:'/get_call_gab_list',
    STOREPAYMENTS:'/getStorePaymentsList',
    INTERACTIONPAYMENTS:'/getInteractionPaymentsList',
    GABPAYMENTSLIST:'/getGabPaymentsList',
    GETORDERDETAILS:'/getOrderDetails',
    SENDINVITATION: '/sendInvitation',
    FOLLOWERLIST: '/getInvitationFollowersList',
    VIDEOSLOTLIST: '/videoStreamSlotList',
    INVITATIONLIST: '/getInvitationList',
    REJECTINVITATION:'/gabRejectInvitations',
    GETGABSLOTDETAILS: '/get_gab_slot_details',
    GETCOUNTRYLIST:'/getCountriesList',
    GETSTATELIST:'/getStatesList',
    GETCITYLIST:'/getCitiesList',
    LIVESTREAMLIST: '/getLiveStreamingList',
    UNBOOKEDSLOTS: '/getUnbookedSlotList',
    FOLLOWERSFOLLOWINGS: '/followers-or-following-list',
    REMOVEGAB: '/removeGab',
    FABPAYMENTSLIST:'/getFabPaymentsList',
    UPCOMINGEVENTS: '/getUpCommingEvents',
    SENDVERIFICATIONCODE: '/send_verification_code',
    VERIFYCODE: "/verify_code"
});
