<template>
  <div>
    <div v-if="window.width <= 992">
      <template v-if="userLogged !== null">
        <mobile-layout />
        <slot />
        <div class="px-5 logged-footer-wrap">
          <Footer className="flex-shrink-0 mobile-auth-footer" />
        </div>
      </template>
      <div 
        v-else 
        class="auth-container-mobile"
      >
        <mobile-auth-layout />
        <div>
          <slot />
          <div class="px-5 footer-wrap">
            <Footer className="flex-shrink-0 mobile-auth-footer" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column min-vh-100"
    >
      <template v-if="userLogged === null">
        <main class="flex-fill">
          <b-row class="layout-container mx-0">
            <b-col
              md="4"
              class="layout-sider"
              :style="{ height: window.height + 'px' }"
            >
              <div class="w-100">
                <img :src="image">
              </div>
            </b-col>
            <b-col
              md="8"
              class="main-content"
            >
              <slot />
              <div class="px-5">
                <Footer className="flex-shrink-0 bg-transparent" />
              </div>
            </b-col>
          </b-row>
        </main>
      </template>
      <template v-else>
        <main class="flex-fill">
          <div class="d-flex flex-row min-vh-100">
            <sidebar />
            <div
              class="flex-grow-1 theme-layout d-flex flex-column align-items-center justify-content-center flex-wrap"
            >
              <slot />
              <div class="w-100">
                <Footer className=" bg-transparent px-5" />
              </div>
            </div>
          </div>
        </main>
      </template>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer/Footer.vue";
import image from "../assets/slide-1.png";
import Sidebar from "../components/Sidebar/Sidebar.vue";
import MobileLayout from "../components/Mobile/MobileLayout.vue";
import MobileAuthLayout from "../components/Mobile/AuthLayout.vue";
//import TabletLayout from "../components/Tablet/TabletLayout.vue";
export default {
    components: { Footer, Sidebar, MobileLayout, MobileAuthLayout },
    data() {
        return {
            userLogged: localStorage.getItem("fullname"),
            image,
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    async created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>
