var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:_vm.style['mobile-layout']},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"white"}},[_c('b-container',{staticClass:"mx-0"},[_c('div',{staticClass:"d-flex flex-row w-100 align-items-center justify-content-between"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.mobile-sidebar",modifiers:{"mobile-sidebar":true}}],class:['bg-transparent', _vm.style['sidebar-toggle']]},[_c('icon-menu',{attrs:{"width":"22","height":"24"}})],1),_c('b-sidebar',{attrs:{"id":"mobile-sidebar","title":"Sidebar","aria-labelledby":"mobile-sidebar-title","shadow":"","no-header":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between flex-row px-4 py-2 align-items-center",attrs:{"id":"mobile-sidebar-title"}},[_c('icon-close',{attrs:{"width":"20","height":"20"},on:{"click":hide}}),_c('div',{staticClass:"flex-grow-1 text-center"},[_c('img',{attrs:{"src":_vm.logo,"alt":"fanpopz-logo","fluid":"","width":"90","height":"90"}})])],1),_c('div',{staticClass:"d-flex justify-content-center flex-column"},[_c('div',{staticClass:"navbar pl-3 pr-3 bg-blue"},[_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":"/"},on:{"click":hide}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Discover ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1),_c('b-link',{class:[
                    'align-items-center w-100 justify-content-between d-none',
                    _vm.style['mobile-nav-link'] ]},[_c('span',[_c('icon-events',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Upcoming Events ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1),(
                    _vm.userLogged === null ||
                      (_vm.userLogged !== null && _vm.account_type == 1)
                  )?_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/celebsearch'}},[_c('span',[_c('icon-connects',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Connects ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.account_type == 2)?_c('b-link',{class:[
                    'align-items-center w-100 justify-content-between d-none',
                    _vm.style['mobile-nav-link'] ]},[_c('span',[_c('icon-connects',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Connects ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e()],1),_c('hr',{staticClass:"hr-primary horizontal-separator"}),_c('div',{staticClass:"navbar pl-3 pr-0 bg-blue"},[(
                    _vm.userLogged === null ||
                      (_vm.userLogged !== null && _vm.account_type == 1)
                  )?_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/followers-following'}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Following ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e(),(
                    _vm.userLogged === null ||
                      (_vm.userLogged !== null && _vm.account_type == 2)
                  )?_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/followers-following'}},[_c('span',[_c('icon-following',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" Followers ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e(),_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":_vm.userLogged === null ? '/register' : '/meetings'}},[_c('span',[_c('icon-my-events',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Events ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1),(_vm.userLogged !== null && _vm.account_type == 2)?_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":_vm.userLogged === null
                      ? '/register'
                      : ("/products/" + _vm.encodeFabId)}},[_c('span',[_c('icon-collection',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Collection ")],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e(),(_vm.userLogged !== null)?_c('router-link',{class:[
                    'align-items-center w-100 justify-content-between',
                    _vm.style['mobile-nav-link'] ],attrs:{"to":"/cart"}},[_c('span',[_c('icon-cart',{staticClass:"mr-2",attrs:{"width":"24","height":"24"}}),_vm._v(" My Cart "),_c('span',{staticClass:"badge",attrs:{"id":"badge"}},[_vm._v(_vm._s(_vm.cartItems == 0 ? "" : _vm.cartItems))])],1),_c('icon-caret',{staticClass:"pt-2",attrs:{"width":"30","height":"30"}})],1):_vm._e()],1),_c('ConfirmLogout',{attrs:{"show":_vm.show},on:{"onClose":_vm.handleClose}})],1)]}}])}),_c('div',{staticClass:"text-center"},[_c('router-link',{staticClass:"navbar-brand font-weight-bold text-center my-6 mr-0",attrs:{"to":_vm.userLogged === null ? '/' : ("/feed/" + _vm.username)}},[_c('img',{attrs:{"src":_vm.logo,"alt":"fanpopz-logo","fluid":"","width":"90","height":"90"}})])],1),_c('b-nav-item-dropdown',{staticClass:"bg-transparent list-unstyled",attrs:{"right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon-user',{attrs:{"width":"25","height":"24"}})]},proxy:true}])},[(_vm.userLogged === null)?_c('b-dropdown-item',{class:_vm.style['user-nav-item'],attrs:{"href":"/register"}},[_vm._v(" Register ")]):_vm._e(),(_vm.userLogged === null)?_c('b-dropdown-item',{class:_vm.style['user-nav-item'],attrs:{"href":"/login"}},[_vm._v(" Login ")]):_vm._e(),(_vm.userLogged !== null)?_c('b-dropdown-item',{class:_vm.style['user-nav-item'],attrs:{"href":"/settings"}},[_vm._v(" Settings ")]):_vm._e(),(_vm.userLogged !== null)?_c('b-dropdown-item',{class:_vm.style['user-nav-item'],on:{"click":_vm.showConfirmLogout}},[_vm._v(" Logout ")]):_vm._e()],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }