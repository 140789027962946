<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
    data() {
        return {
            userLogged: localStorage.getItem("fullname"),
        };
    },
};
</script>
