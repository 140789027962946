<template>
  <div>
    <div v-if="window.width <= 992">
      <mobile-layout />
      <slot />
    </div>
    <div
      v-else
      class="d-flex flex-row min-vh-100"
    >
      <sidebar />
      <slot />
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar/Sidebar.vue";
import MobileLayout from "../components/Mobile/MobileLayout.vue";
export default {
    components: {
        Sidebar,
        MobileLayout,
    },
    data: function () {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    async created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>
