import Vue from 'vue';
import App from './App.vue';
import {router} from './routes';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import LayoutDefault from "./layouts/LayoutDefault.vue";
import LayoutHome from "./layouts/LayoutHome.vue";
import LayoutGab from './layouts/LayoutGab.vue';
import LayoutStaticPage from './layouts/LayoutStaticPage.vue';
import '@/styles/_global.scss';
import VeeValidate from "vee-validate";
import Select2 from 'v-select2-component';
import { GOOGLE_CLIENT_ID } from './config/config';
import moment from 'moment';
import ZoomOnHover from "vue-zoom-on-hover";
import { BSidebar } from 'bootstrap-vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'; // import the croppie css manually


Vue.prototype.moment = moment;

Vue.component('LayoutDefault', LayoutDefault);
Vue.component('LayoutHome', LayoutHome);
Vue.component('LayoutGab', LayoutGab);
Vue.component('LayoutStaticPage', LayoutStaticPage);
// Vue.component("v-select", vSelect);
Vue.component('Select2', Select2);
Vue.component('b-sidebar', BSidebar);

Vue.use(VueCroppie);
Vue.use(ZoomOnHover);
Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: "veeFields",
    errorBagName: "veeErrors"
});

import GoogleAuth from '@/config/google_oAuth.js';

const gauthOption = {
    clientId: GOOGLE_CLIENT_ID,
    scope: 'profile email',
    prompt: 'select_account'
};

Vue.use(GoogleAuth, gauthOption);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
new Vue({
    store,
    router:router,
    render: h => h(App),
}).$mount('#app');
